import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader, BannerLeft } from "../utils"
import img from "../images/bcg/burgers-and-brews.jpg"

const SpecialsPage = () => (
  <Layout>
    <SEO title="Specials" keywords={[`Foragers Canteen daily specials`]} />
    <PageHeader img={img}>
      <BannerLeft
        title="Specials"
        subtitle="All the flavor. Part of the price."
      >
        <hr />
      </BannerLeft>
    </PageHeader>
    <section
      id="specials-menu"
      className="row px-0 py-5 justify-content-center"
    >
      <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0 specials-column">
        <div className="row justify-content-center specials-row" id="tuesday">
          <div className="col-11 mx-0 px-2 px-md-3 pt-3 my-0 special-title">
            <h2>Taco Train Tuesdays</h2>
          </div>
          <div className="col-11 mx-0 px-2 py-1 px-md-3 special-content">
            <div className="my-0 py-2 dish">
              <p>
                3 tacos, your choice - <strong>$9.99</strong>
              </p>
            </div>
            <div className="my-0 py-2 dish">
              <p>
                Margaritas by the glass and/or pitcher -{" "}
                <strong>50% off</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center specials-row" id="wednesday">
          <div className="col-11 mx-0 px-2 px-md-3 pt-3 my-0 special-title">
            <h2>Wednesdays</h2>
          </div>
          <div className="col-11 mx-0 px-2 py-1 px-md-3 special-content">
            <div className="my-0 py-2 dish">
              <p>
                <strong>
                  Burgers and Brews
                  <br />
                </strong>
                The Depot Cheeseburger with tater planks -{" "}
                <strong>$7.99</strong>
                <br />
                Back Porch Burger with tater planks - <strong>$9.99</strong>
                <br />
                The Kitchen Sink Burger - <strong>$12.99</strong>
                <br />
                All bottle and draft beers - <strong>50% off</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SpecialsPage
